import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SubPageHeader from '../../components/SubPageHeader';
import { Save, Saved, Add } from '../../utils/icons';
import Loading from '../../components/Loading';
import '../../styles/Collections.scss';
import AddSubscription from '../../components/products/AddSubscription';
import { toDataURL } from '../../utils/offline-image';

export default function Product({subView, setSubView, isOffline, isIOS, updatesWaiting}) {

  const [product, setProduct] = useState(null)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [viewer, setViewer] = useState(false)
  const [db, setDB] = useState(null)
  const [saved, setSaved] = useState([])
  const [favorites, setFavorites] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openAdd, setOpenAdd] = useState(false)
  const [selected, setSelected] = React.useState({});
  const [api, setAPI] = useState(null)
  const [sayduckInit, setSayduckInit] = useState(false)

  const getProduct = async () => {
    const getDB = await openDB(process.env.REACT_APP_INDB_NAME)
    setDB(getDB)

    const getSaved = await getDB.getAllFromIndex('saved', 'id')
    console.log('get saved', getSaved)
    setSaved(getSaved)

    const getFavorites = await getDB.get('favorites', Number(subView.id))
    console.log('get fav', getFavorites)
    setFavorites(getFavorites)

    let productResJson;

    if (isOffline){
        const productRes = await getDB.getAllFromIndex('products', 'id', Number(subView.id))
        productResJson = productRes[0]
        productResJson.image_data_url = await toDataURL(productResJson.image_url)
    }
    else {
        const productRes = await getDB.getAllFromIndex('products', 'id', Number(subView.id))
        productResJson = productRes[0]
    }

    let optsWithImg = []

    for (let i=0; i < productResJson.variants.edges.length; i++) {
        const newOpt = {...productResJson.variants.edges[i].node}
        newOpt.label = productResJson.variants.edges[i].node.productOptions.edges[0].node.values.edges[0].node.label
        newOpt.sku = productResJson.variants.edges[i].node.sku
        newOpt.colorCode = productResJson.variants.edges[i].node.productOptions.edges[0].node.values.edges[0].node.hexColors[0]
        if (isOffline){
            const getArFile = await getDB.getAllFromIndex('ar-ref-apple', 'id', productResJson.variants.edges[i].node.entityId)
            if (getArFile.length > 0) {
                const getArFileName = getArFile[0].url.split('/')
                newOpt.arURLName = getArFileName[getArFileName.length - 1].split("?")[0]
                newOpt.arURL = getArFile[0] ? await arDataFile(getArFile[0].url) : false
            }
            else {
                newOpt.arURL = false
            }
            newOpt.image = await toDataURL(productResJson.variants.edges[i].node.defaultImage.url)
        }
        else {
            newOpt.arURL = false
            newOpt.image = productResJson.variants.edges[i].node.defaultImage.url
        }
        console.log(newOpt)
        optsWithImg.push(newOpt)
    }

    setOptions(sortVariants(optsWithImg))

    const defaultOpt =  optsWithImg.find(o => o.productOptions.edges[0].node.values.edges[0].node.isDefault)
    if (defaultOpt) {
        setSelectedOption(defaultOpt)
    }
    else {
        setSelectedOption(optsWithImg[0])
    }

    setProduct(productResJson)
    setIsLoading(false)
  }

  const getViewer = () => {

    const viewerContainer = document.querySelector('.sayduck-3d-viewer-container2')
    const sayduckViewer = document.createElement('sayduck-viewer')
    sayduckViewer.setAttribute('mode', "variants")
    sayduckViewer.setAttribute('background', "transparent")
    sayduckViewer.setAttribute('hide-controls-hint', "")
    sayduckViewer.setAttribute('hide-product-info', "")
    sayduckViewer.setAttribute('hide-embed', "")
    sayduckViewer.setAttribute('hide-dimensions', "")
    sayduckViewer.setAttribute('hide-annotations', "")
    sayduckViewer.setAttribute('hide-fullscreen', "")
    sayduckViewer.setAttribute('hide-branding', "")

    if (selectedOption !== null) {
        const vUUID = selectedOption.sku
        sayduckViewer.setAttribute('initial-variant', vUUID)

        if (sayduckInit) {
            api.variants.setActiveVariant(vUUID);
        }
    }

    sayduckViewer.setAttribute('include-variants', `[${product.variants.edges.map(v => `"${v.node.sku}"`).join(',')}]`)

    viewerContainer.appendChild(sayduckViewer)
    sayduckViewer.setAttribute('product', product.custom_fields.edges.find(cf => cf.node.name === "app-sayduck").node.value)
    viewerContainer.style.display = 'block'

    const s = document.createElement('script')
    s.src = 'https://viewer.sayduck.com'
    document.body.appendChild(s)
  }

  const arDataFile = (arURL) => {
    return new Promise((resolve, reject) => {
        caches.match(arURL)
        .then(response => response.blob())
        .then((blob) => {
            const reader = new FileReader()
            reader.onloadend = () => {
                const toMime = reader.result.replace('data:application/zip', 'data:model/vnd.usdz+zip')
                resolve(toMime)
            }
            reader.readAsDataURL(blob)
        })
    })
  }

  useEffect(() => {
   getProduct()

   window.addEventListener('sayduck.api-ready', (event) => {
        setAPI(event.detail.instance)
        setSayduckInit(true)
    });

    const request = new CustomEvent('sayduck.request-api', {
        bubbles: true,
        composed: true,
        detail: {
        instance: {},
        },
    });
        window.dispatchEvent(request);
  }, []);

  useEffect(() => {
    if (!viewer) {
        if (document.querySelector('.sayduck-3d-viewer-container2')) {
            document.querySelector('.sayduck-3d-viewer-container2').style.display = 'none'
        }
    }
   }, [viewer]);

  const productAddClick = async (e, id) => {
    e.preventDefault()
    e.stopPropagation();

    console.log('saved', saved)
    console.log('id', id)
    
    setSelected(product)
    setOpenAdd(true)
  }

  const productSaveClick = async (e, id, name, isFav) => {
    e.preventDefault()
    e.stopPropagation();
    console.log('save')
    
    if (isFav) {
        await db.delete('favorites', Number(id));
        setFavorites(null)
    }
    else {
        await db.add('favorites', {
            'id': id,
            'name': name
        });
        setFavorites({
            'id': id,
            'name': name
        })
    }
  }

  const productTryOnClick = (e, arURL) => {
    e.preventDefault()
    e.stopPropagation();
    console.log('try on')
    caches.match(arURL)
    .then(response => response.blob())
    .then((blob) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            const toMime = reader.result.replace('data:application/zip', 'data:model/vnd.usdz+zip')
            const anchor = document.createElement('a');
            anchor.setAttribute('rel', 'ar');
            anchor.appendChild(document.createElement('img'));
            anchor.setAttribute('href', toMime);
            anchor.click();
        }
        reader.readAsDataURL(blob)
    })
  }

  const product360Click = (e) => {
    e.preventDefault()
    e.stopPropagation();
    console.log('360')
    setViewer(true)
    getViewer()
  }

  const productSayduckTryon = () => {
    api.scene.launchWebAr();
  }

  return (
    isLoading ?
        <Loading />
    :
    <Box className='background-color-wrapper' style={{ backgroundColor: "#FFFFFF"}}>
        <Box className='app-sub-page products product'>
            <SubPageHeader title={subView.name} subView={subView} setSubView={setSubView} viewer={viewer} setViewer={setViewer} updatesWaiting={updatesWaiting} />
            {!viewer &&
                <Box className='collection-grid'>
                    <Grid container direction="column" className='collection-grid-container'>
                        {product && 
                            <Grid key={product.id} item className="collection-grid-item">
                                <Grid container direction="row">
                                    <Grid item flexBasis={'100%'}>
                                        <img className='product-image' src={selectedOption.image} alt="" />
                                    </Grid>
                                    <Grid item flexBasis={'60%'}>
                                        <p className='product-swatch-label'><strong>Color: </strong>{selectedOption.label}</p>
                                        <Grid container direction="row" className='product-swatch-options'>
                                            {options && options.length > 0 &&
                                                options.map((o) => {
                                                    return (
                                                        <Grid key={o.id} item className={`product-swatch-option ${o.id === selectedOption.id ? 'selected' : ''}`}>
                                                            <Button className='collection-grid-btn product-swatch-btn' sx={{backgroundColor: `${o.colorCode}`}} onClick={(e) => setSelectedOption(o)}>
                                                            </Button>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item flexBasis={'40%'} textAlign={'right'}>
                                        <Button className='collection-grid-btn add-save' onClick={(e) => productSaveClick(e, product.id, product.name, favorites)} aria-label={favorites ? "Remove from favorites" : "Save to favorites"}>{favorites ? <Saved /> : <Save />}</Button>
                                        <Button className={`collection-grid-btn add-save`} onClick={(e) => productAddClick(e, product.id)} aria-label="Add to collections"><Add /></Button>
                                    </Grid>
                                    {isIOS && selectedOption.arURL && isOffline &&
                                        <Grid item flexBasis={`100%`} marginTop={'16px'} paddingRight={'8px'}>
                                            <a className='MuiButton-root product' id={`arLink`} href={selectedOption.arURL} download={selectedOption.arURLName} rel='ar' ><img />Try on</a>
                                        </Grid>
                                    }
                                    {!isOffline &&
                                        <Grid item flexBasis={'100%'} marginTop={'16px'}>
                                            <Button variant='outlined' className='product' onClick={(e) => product360Click(e)}>TRY ON</Button>
                                        </Grid>
                                    }
                                    <Grid item flexBasis={'100%'} marginTop={'16px'}>
                                        <div dangerouslySetInnerHTML={{"__html": product.description}}></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Box>    
            }
            <Box>
                    {product && 
                        <div className="sayduck-3d-viewer-container2" style={{minHeight: '400px', minWidth: '300px', width: '100%', height: '466px', position: 'relative', margin: '0 auto', paddingBottom: '66px', backgroundColor: '#FFFFFF', borderRadius: '12px'}}>
                            {isIOS && <Button variant='outlined' className='product sayduck-tryon' onClick={(e) => productSayduckTryon()}>Try on</Button>}
                        </div>
                    }
                </Box>
                <AddSubscription openAdd={openAdd} setOpenAdd={setOpenAdd} db={db} setSaved={setSaved} saved={saved} selected={selected} setSelected={setSelected} />
        </Box>
    </Box>
  )
}

const sortVariants = (variants) => {
    // find the variant with product option that isDefault and make it the first in the array
    const defaultVariant = variants.find(v => v.productOptions.edges[0].node.values.edges[0].node.isDefault)
    if (defaultVariant) {
        const otherVariants = variants.filter(v => !v.productOptions.edges[0].node.values.edges[0].node.isDefault)
        return [defaultVariant, ...otherVariants]
    }
    return variants
}